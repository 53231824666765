"use client";
import React from "react";
import { WcStack } from "@bpipartners/webui";
import LoginComposite from "../../../components/composites/login/LoginComposite";

export default function Index() {
  return (
    <WcStack
      paddingTop={10}
      alignItems="center"
      width="100%"
      id="LoginPage"
      data-cy="loginDiv"
    >
      <LoginComposite />
    </WcStack>
  );
}
